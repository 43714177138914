import React from 'react';
import { Link } from 'gatsby';
import { LANDING, SIGN_IN } from '../../constants/routes';
import Logo from '../../assets/logo.svg';
import SignOutButton from './SignOutButton';
import cn from 'classnames';

import style from './NavBar.scss';

const NavigationAuth = ({ authUser }) => (
  <div className={style.navbar}>
    <div className={style.inner}>
      <div className={style.left}>
        <div className={style.logo}>
          <img src={Logo} />
        </div>
        <div className="d-flex align-items-center">
          {authUser ? (
            <>
              <div className={style.link}>
                <Link className={style.name} to={LANDING}>
                  Dashboard
                </Link>
              </div>
              {/* <div className={style.link}>
                <Link to={'/comments'}>Comments</Link>
              </div> */}
              <div className={style.link}>
                <Link to={'/paymentSettings'}>Payment settings</Link>
              </div>
              <div className={cn(style.link, style.disabled)}>
                <Link to={'/stats'}>
                  Stats
                </Link>
              </div>
              <div
                className={cn(style.link)}
              >
                <a target="_blank" href={'/liveDemo.html?settingsId=' + authUser.uid}>Live demo</a>
              </div>
              <div className={cn(style.link, style.disabled)}>
                <Link to={'/adminDashboard'}>Admin dashboard</Link>
              </div>
            </>
          ) : (
            <div className={style.link}>
              <Link className={style.name} to={LANDING}>
                Home
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className={style.user}>
        <div className={style.link}>
          {authUser ? (
            <div className="d-flex align-items-center">
              <div className={style.link}>
                <Link to={'/profile'}>Profile</Link>
              </div>
              <SignOutButton />
            </div>
          ) : (
            <Link to={SIGN_IN}>Sign In</Link>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default NavigationAuth;
